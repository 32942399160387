import { useRef, useEffect, useState, useContext } from 'react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { AppContext } from '../scripts/context'
import { GetSectionName } from '../scripts/utilities';

export default function Footer() {

    const navigate = useNavigate();
    const { page } = useParams();
    
    const contactContainerStatusRef = useRef(false);
    const contactContainerRunningRef = useRef(false);

    const [contactContainerBottomState, setContactContainerBottomState] = useState(getContactContainerOffsetCollapsed());

    const [contactButtonFillState, setContactButtonFillState] = useState("#333333");
    const [contactButtonArrowState, setContactButtonArrowState] = useState(false);
    const [scrollButtonArrowState, setScrollButtonArrowState] = useState(true);
    const [scrollButtonState, setScrollButtonState] = useState(false);

    const {
        handleCheckoutPanel,
		scrollToTitle,
		scrollToOverview,
		scrollToTop,
        getScrollPosition,
        getFadeEndTop,
        getFadeStartBottom
    } = useContext(AppContext);

    const currentYear = new Date().getFullYear();

    const location = useLocation();

    function handleScroll() {

        if (contactContainerStatusRef.current === true && contactContainerRunningRef.current === false) {

            collapseContactContainer();
        
        };

        handleScrollButtonArrowState();

    };

    function handleScrollButtonArrowState() {

        const scrollPos = getScrollPosition();
        const fadeStartBottom = getFadeStartBottom();

        if (scrollPos < fadeStartBottom - 0.05) {

            setScrollButtonArrowState(true);

        } else if (scrollPos >= fadeStartBottom - 0.05) {

            setScrollButtonArrowState(false);

        };

    };

    function handleResize() {

        let contactContainerOffset;

        if (contactContainerStatusRef.current === false) {

            contactContainerOffset = getContactContainerOffsetCollapsed();

        } else {

            contactContainerOffset = getContactContainerOffsetExpanded();

        };

        setContactContainerBottomState(contactContainerOffset);

    };

    function getContactContainerOffsetCollapsed() {

        let contactContainerOffset = -266;

        if (window.innerWidth < 1300 && window.matchMedia('(hover: none)').matches && window.innerWidth < window.innerHeight) {

            contactContainerOffset = -269 / 360 * window.innerWidth;

        };

        return contactContainerOffset;

    };

    function getContactContainerOffsetExpanded() {

        let contactContainerOffset = -10;

        if (window.innerWidth < 1300 && window.matchMedia('(hover: none)').matches && window.innerWidth < window.innerHeight) {

            contactContainerOffset = -13 / 360 * window.innerWidth;

        };

        return contactContainerOffset;

    };

    function handleContactButtonClick() {

        if (contactContainerStatusRef.current === false && contactContainerRunningRef.current === false) {

            expandContactContainer();

        } else if (contactContainerRunningRef.current === false) {

            collapseContactContainer();

        };

    };

    function expandContactContainer() {

        contactContainerRunningRef.current = true;

        let contactContainerOffset = getContactContainerOffsetCollapsed();
        let contactContainerTarget = getContactContainerOffsetExpanded();
        let contactContainerAnimStep = 10;

        let intervalContactContainer = setInterval(expandContactContainerInt, 5);
        function expandContactContainerInt() {
            if (contactContainerOffset !== contactContainerTarget) {
                contactContainerOffset = Math.min(contactContainerOffset + contactContainerAnimStep, contactContainerTarget);
                setContactContainerBottomState(contactContainerOffset);
            } else {
                clearInterval(intervalContactContainer);

                setContactButtonArrowState(true);

                contactContainerStatusRef.current = true;
                contactContainerRunningRef.current = false;;

            };
        
        };

    };

    function collapseContactContainer() {

        contactContainerRunningRef.current = true;

        let contactContainerOffset = getContactContainerOffsetExpanded();
        let contactContainerTarget = getContactContainerOffsetCollapsed();
        let contactContainerAnimStep = -10;

        let intervalContactContainer = setInterval(collapseContactContainerInt, 5);
        function collapseContactContainerInt() {
            if (contactContainerOffset !== contactContainerTarget) {
                contactContainerOffset = Math.max(contactContainerOffset + contactContainerAnimStep, contactContainerTarget);
                setContactContainerBottomState(contactContainerOffset);
            } else {
                clearInterval(intervalContactContainer);
                
                setContactButtonArrowState(false);

                contactContainerStatusRef.current = false;
                contactContainerRunningRef.current = false;;

            };
        
        };

    };

    function handleButtonScrollClick() {

        const sectionName = GetSectionName(page);

        const scrollPos = getScrollPosition();
        const fadeStartBottom = getFadeStartBottom();
        const fadeEndTop = getFadeEndTop();

        const bufferZone = 0.05;

        handleCheckoutPanel(false);

        if (scrollPos < fadeEndTop - bufferZone) {
    
            scrollToTitle("smooth");
    
        } else if (scrollPos >= fadeEndTop - bufferZone && scrollPos < fadeStartBottom - bufferZone) {

            if (!page || page === "about") {

                navigate("/about");

            } else {

                navigate("/" + page + "/" + sectionName);

            };

            scrollToOverview("smooth");

        } else if (scrollPos >= fadeStartBottom - bufferZone && scrollPos < fadeStartBottom + bufferZone) {

            if (!page || page === "about") {

                navigate("/");

            } else {

                navigate("/" + page);

            };

            scrollToTop("smooth");
            
        } else if (scrollPos >= (fadeStartBottom + bufferZone)) {

            if (!page || page === "about") {

                navigate("/about");

            } else {

                navigate("/" + page + "/" + sectionName);

            };

            scrollToOverview("smooth");
        
        };

    };

    //useEffect on intial render
    useEffect(() => {

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);

        //handleScroll();
        handleScrollButtonArrowState();
        setScrollButtonState(true);

        return () => {

            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);

        };
                                                                    // eslint-disable-next-line
    }, []);

    //useEffect on every render
    useEffect(() => {

        handleScrollButtonArrowState();
                                                                    // eslint-disable-next-line
    });

    const footer = {
        contact_svg:

        <svg version="1.1" id="contact_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 527.77 308" preserveAspectRatio="xMinYMax meet" pointerEvents="none">

            <path id="contact_background" fill="#333333" d="M527.774,308H0V52l20.749-35.938c1.925-3.334,6.65-6.063,10.5-6.063h191.525c3.85,0,9.228,2.229,11.95,4.95L527.774,308z"/>
            <defs>
                <pattern y="2.5" x="1" width="5" height="5" patternUnits="userSpaceOnUse" id="pattern0" viewBox="0 0 5 5">
                    <polygon fill="#222222" points="1,0.5 4.5,0.5 4.5,4" />
                    <polygon fill="#222222" points="0.5,1 4,4.5 0.5,4.5" />
                </pattern>
            </defs>
            <path fill="url(#pattern0)" d="M527.774,308H0V52l5.773-10l256.001,0.001L527.774,308z"/>
            <path id="contact_button" fill={contactButtonFillState} d="M5.774,42l14.976-25.938c1.925-3.334,6.649-6.063,10.5-6.063h191.524c3.851,0,9.229,2.229,11.95,4.95l27.053,27.052L5.774,42z" pointerEvents="auto" onPointerDown={() => {setContactButtonFillState("#B2B2B2")}} onPointerUp={() => {setContactButtonFillState("#333333")}} onPointerLeave={() => {setContactButtonFillState("#333333")}} onTouchStart={() => {setContactButtonFillState("#B2B2B2")}} onTouchEnd={() => {setContactButtonFillState("#333333")}} onClick={() => {handleContactButtonClick()}}/>
            {contactButtonArrowState ? 
                <path id="contact_button_down" fill="#FFFFFF" d="M39.781,33.174c1.098,1.1,2.896,1.1,3.994,0l14.079-14.078c1.097-1.098,0.857-1.428-0.531-0.734l-13.02,6.511c-1.391,0.694-3.663,0.694-5.051,0L26.23,18.362c-1.39-0.693-1.628-0.364-0.529,0.734L39.781,33.174z"/> 
                : 
                <path id="contact_button_up" fill="#FFFFFF" d="M43.771,18.824c-1.098-1.1-2.896-1.1-3.994,0L25.698,32.902C24.601,34,24.84,34.33,26.229,33.637l13.02-6.511c1.391-0.694,3.663-0.694,5.051,0l13.021,6.511c1.39,0.693,1.628,0.364,0.529-0.734L43.771,18.824z"/>
            }
        </svg>,

        contact_title:

        <div id="contact_title">contact</div>,

        contact_details:

        <div id="contact_details">

            <div id="contact_text_title">njb design llc<br/></div>
            <div id="contact_text_medium"><a href="mailto:contact@njb.design">contact@njb.design</a></div>
            <div id="contact_text"><a href="tel:+41323416655">+41 32 341 66 55</a><br/><br/></div>
            <div id="contact_text">Winterholzstrasse 55 | CH-3018 Bern | Switzerland<br/><br/></div>
            <div id="contact_text_medium"><a href="/njb_design_privacy_policy_en.pdf">privacy policy <small id="contact_text_detail">[PDF]</small></a><br/></div>
            <div id="contact_text_detail">CHE-406.932.341<br/><br/></div>
            <div id="contact_text_detail">njb design ® is a protected trademark in CA, CH, EU, UK and US.</div>
            <div id="contact_text_detail">Copyright © 2000-{currentYear} njb design llc. All rights reserved.</div>

        </div>,

        copyright_svg:

        <svg version="1.1" id="copyright_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 119.31 36" preserveAspectRatio="xMaxYMax meet" pointerEvents="none">

            <path d="M119.307,36H0l13.678-23.691c0.734-1.27,2.533-2.309,4-2.309H90.64c1.466,0,3.516,0.848,4.553,1.887L119.307,36z" pointerEvents="auto"/>

        </svg>,

        copyright_title:

        <div id="copyright_title">© njb design llc</div>,

        scroll_button:

        <svg version="1.1" id="scroll_button_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 64" preserveAspectRatio="xMaxYMax meet" pointerEvents="none">

            {scrollButtonArrowState ? 
                <path id="scroll_button_down" fill="#FFFFFF" d="M56.469,44.95c1.943,1.944,5.126,1.944,7.069,0l24.93-24.929c1.944-1.944,1.521-2.528-0.938-1.301L64.474,30.249c-2.46,1.229-6.484,1.229-8.943,0L32.474,18.721c-2.46-1.229-2.882-0.645-0.938,1.301L56.469,44.95z"/>
                : 
                <path id="scroll_button_up" fill="#FFFFFF" d="M63.531,19.05c-1.943-1.944-5.126-1.944-7.069,0l-24.93,24.929c-1.944,1.944-1.521,2.528,0.938,1.301l23.057-11.528c2.46-1.229,6.484-1.229,8.943,0l23.057,11.528c2.46,1.229,2.882,0.645,0.938-1.301L63.531,19.05z"/>
            }
            
            <rect id="scroll_button_link" opacity="0" x="0" width="120" height="64" pointerEvents="auto" onClick={() => {handleButtonScrollClick()}}/>

        </svg>

    };

    return (
        <footer id="footer">
            <div id="contact_container" style={{bottom: contactContainerBottomState + "px"}}>
                {footer.contact_svg}
                {footer.contact_title}
                {footer.contact_details}
            </div>
            <div id="copyright_container">
                {footer.copyright_svg}
                {footer.copyright_title}
            </div>
            {location.pathname !== "/404" && location.pathname !== "/500" && scrollButtonState === true ? footer.scroll_button : null}
        </footer>
    );
};