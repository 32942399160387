export function Debounce(fn, ms) {

    let timer;

    return _ => {

        clearTimeout(timer);
        timer = setTimeout(_ => {

            timer = null;
            fn.apply(this, arguments);

        }, ms);

    };

};

export function GetSectionName(page) {

    if (page === "store") {

        return (
            "products"
        );

    } else {

        return (
            "projects"
        );
        
    };

};