import { useEffect, useState, useRef, useContext } from 'react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { AppContext } from '../scripts/context'

import PayPalButton from './paypalButton';

export default function Header() {

	const location = useLocation();

	const {
		handleMenu,
		menuElements,
		setMenuLocked,
		setMediaLoaderVisState,
        setMediaLoaderOpaState,
		checkoutPanelState,
		checkoutPanelContentState,
		setCheckoutPanelContentState,
		checkoutPanelCountryState,
		setCheckoutPanelCountryState,
		paypalSuccessState,
		shippingCostState,
		setShippingCostState,
		handleCheckoutPanel,
		checkoutPanelButtonState,
		cartContentState,
		setCartContentState,
		scrollToTop,
		exRateChfUsdRef,
		backendUrl
    } = useContext(AppContext);

	const [logoFlagFillState, setLogoFlagFillState] = useState("#333333");
	const [countryButtonFillState, setCountryButtonFillState] = useState("#00000066");
	const [checkoutPanelCountryPanelState, setCheckoutPanelCountryPanelState] = useState(false);
	const [cartTotalState, setCartTotalState] = useState(0);
	const [shippingDaysState, setShippingDaysState] = useState(0);
  	const [error, setError] = useState(null);

	const checkoutPanelRef = useRef();
	const shippingCountriesRef = useRef([]);
	const paypalOrderIdRef = useRef();

  	// Function to fetch the shipping estimate at localhost:3001/api/countries
  	const fetchShippingEstimate = async () => {
  	  	try {

  	  	  	const response = await fetch(`${backendUrl}/api/countries`, {
  	  	  	  	method: 'GET',
  	  	  	  	headers: {
  	  	  	  	  	'Content-Type': 'application/json',
  	  	  	  	},
  	  	  	});

  	  	  	if (!response.ok) {
  	  	  	  	throw new Error(`Network response was not ok, status: ${response.status}`);
  	  	  	};

  	  	  	const data = await response.json();

  	  	  	// Validate response is an array
  	  	  	if (!Array.isArray(data)) {
  	  	  	  	throw new Error('Invalid response from server');
  	  	  	};

  	  	  	shippingCountriesRef.current = data;

  	  	} catch (error) {

  	  	  	console.error('Failed to fetch shipping estimate:', error.message);

  	  	};
  	};

  	const navigate = useNavigate();

	const { page } = useParams();

	function setPaypalOrderIdRef(id) {
		paypalOrderIdRef.current = id;
	};

	function getCartTotal(cartContentTemp) {

		let cartTotal = 0;

		if (cartContentTemp.length > 0) {

			cartContentTemp.forEach(value => {
				cartTotal = cartTotal + value.price * value.count;
			});

			cartTotal = cartTotal + shippingCostState;

			setCartTotalState(cartTotal);

		};

		return cartTotal

	};

	function cartContentUpdate(cartContentTemp) {

		let cartContentHasChanged = false;

		if (cartContentTemp.length !== cartContentState.length) {

			cartContentHasChanged = true;

		} else if (cartContentTemp.length > 0 && cartContentState.length > 0) {

			for (let index = 0; index <= cartContentTemp.length - 1; index++) {

				if (cartContentTemp[index].count !== cartContentState[index].count) {

					cartContentHasChanged = true;
					break;

				};

			};

		}
		
		if (cartContentTemp.length === 0) {

			handleCheckoutPanel(false, true);

		} else if (cartContentHasChanged === true) {

			setCartTotalState(getCartTotal(cartContentTemp));
			setCartContentState(cartContentTemp);
			localStorage.setItem("cartContent", JSON.stringify(cartContentTemp));

		};

	};

    const menu = {
        svg:

        <svg version="1.1" id="menu_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 360 360" preserveAspectRatio="xMinYMin meet" pointerEvents="none">

            <path id="path_home" ref={menuElements.pathHome} strokeDasharray="169,169" strokeDashoffset="0" fill="none" stroke="#FFFFFF" strokeWidth="5" strokeMiterlimit="10" d="M62,10.8l56.065,56.064c1.945,1.945,5.786,3.536,8.536,3.536H167.8c2.75,0,6.591,1.591,8.536,3.536L196.95,94.55"/>
			<path id="circle_home_0" ref={menuElements.circleHome0} strokeDasharray="13,13" fill="none" stroke="#FFFFFF" strokeWidth="2.5" strokeMiterlimit="10" d="M196.949,94.551c1.563-1.563,4.095-1.563,5.657,0c1.562,1.562,1.562,4.095,0,5.657"/>
			<path id="circle_home_1" ref={menuElements.circleHome1} strokeDasharray="13,13" fill="none" stroke="#FFFFFF" strokeWidth="2.5" strokeMiterlimit="10" d="M196.95,94.55c-1.563,1.562-1.563,4.095,0,5.657c1.562,1.562,4.095,1.562,5.656,0"/>
            <g id="text_home">
                <path id="text_home_h" ref={menuElements.textHomeH} fill="#FFFFFF" d="M207.332,111.09l12.338-12.34l1.234,1.235l-4.806,4.806c0.656-0.236,1.324-0.23,2,0.021
		            c0.678,0.252,1.396,0.758,2.159,1.521c1.144,1.144,1.714,2.175,1.711,3.095c-0.003,0.92-0.584,1.96-1.744,3.12l-5.718,5.718
		            l-1.293-1.293l5.444-5.444c0.91-0.911,1.377-1.645,1.396-2.2c0.021-0.555-0.358-1.219-1.132-1.992
		            c-0.91-0.912-1.785-1.372-2.622-1.38c-0.837-0.009-1.708,0.44-2.614,1.347l-5.071,5.071L207.332,111.09z"/>
                <path id="text_home_o" ref={menuElements.textHomeO} fill="#FFFFFF" d="M220.15,124.141c-1.303-1.305-1.961-2.469-1.972-3.497c-0.011-1.027,0.622-2.179,1.897-3.456
		            l2.071-2.07c1.281-1.282,2.436-1.919,3.459-1.91c1.025,0.008,2.188,0.661,3.484,1.959l0.604,0.605
		            c1.304,1.303,1.961,2.469,1.974,3.496c0.01,1.027-0.625,2.183-1.906,3.465l-2.072,2.07c-1.28,1.282-2.434,1.918-3.455,1.906
		            c-1.021-0.011-2.182-0.666-3.479-1.964L220.15,124.141z M226.648,123.18l1.49-1.492c1.1-1.1,1.66-1.939,1.683-2.519
		            c0.022-0.581-0.415-1.317-1.31-2.213l-0.605-0.604c-0.889-0.89-1.618-1.322-2.19-1.297c-0.571,0.024-1.41,0.59-2.515,1.694
		            l-1.491,1.491c-1.111,1.111-1.677,1.953-1.699,2.527c-0.021,0.574,0.409,1.304,1.293,2.188l0.605,0.605
		            c0.889,0.889,1.627,1.322,2.212,1.301S225.549,124.279,226.648,123.18z"/>
                <path id="text_home_m" ref={menuElements.textHomeM} fill="#FFFFFF" d="M226.879,130.637l8.727-8.725l1.26,1.259l-1.192,1.192c0.629-0.264,1.265-0.289,1.906-0.074
		            c0.641,0.217,1.311,0.674,2.013,1.376c0.74,0.74,1.206,1.433,1.396,2.076c0.19,0.643,0.135,1.321-0.17,2.034
		            c0.764-0.254,1.467-0.285,2.108-0.096c0.645,0.191,1.303,0.623,1.977,1.297c1.145,1.144,1.71,2.163,1.699,3.059
		            c-0.012,0.895-0.604,1.93-1.781,3.107l-5.719,5.717l-1.293-1.293l5.313-5.312c0.973-0.972,1.482-1.734,1.532-2.286
		            c0.05-0.553-0.29-1.193-1.02-1.923c-0.807-0.807-1.6-1.188-2.379-1.144c-0.778,0.044-1.623,0.521-2.535,1.434l-5.071,5.071
		            l-1.251-1.251l5.908-5.908c0.69-0.691,1.031-1.283,1.023-1.777c-0.01-0.495-0.366-1.096-1.073-1.803
		            c-0.829-0.828-1.647-1.229-2.457-1.197c-0.81,0.029-1.661,0.492-2.557,1.388l-5.071,5.071L226.879,130.637z"/>
                <path id="text_home_e" ref={menuElements.textHomeE} fill="#FFFFFF" d="M244.736,148.727c-1.303-1.305-1.961-2.47-1.972-3.497c-0.011-1.028,0.622-2.179,1.897-3.456
		            l2.071-2.07c1.281-1.282,2.436-1.919,3.459-1.911c1.025,0.009,2.188,0.662,3.484,1.96l0.604,0.605
		            c1.304,1.303,1.962,2.469,1.973,3.496s-0.624,2.183-1.905,3.465l-1.318,1.316l-6.28-6.264c-0.095,0.082-0.195,0.176-0.308,0.281
		            c-0.108,0.105-0.262,0.254-0.455,0.447c-0.899,0.9-1.36,1.648-1.38,2.241c-0.019,0.595,0.407,1.327,1.28,2.2l0.605,0.605
		            c0.777,0.778,1.432,1.208,1.959,1.288c0.526,0.081,1.096-0.134,1.702-0.642l1.343,1.342c-1.011,0.879-1.996,1.258-2.957,1.135
		            c-0.962-0.121-2.027-0.768-3.199-1.938L244.736,148.727z M247.895,141.227l4.938,4.939l0.34-0.34
		            c0.818-0.818,1.227-1.52,1.227-2.105c0-0.585-0.434-1.312-1.301-2.179l-0.604-0.604c-0.861-0.862-1.582-1.29-2.162-1.285
		            c-0.58,0.006-1.279,0.418-2.097,1.234L247.895,141.227z"/>
            </g>
            <line id="link_home" ref={menuElements.linkHome} opacity="0" fill="none" stroke="#000000" strokeWidth="40" strokeMiterlimit="10" strokeLinecap="round" x1="202.606" y1="100.207" x2="266.606" y2="164.207" pointerEvents="auto" onClick={() => {navigateMenu("/")}}/>

            <line id="path_digital" ref={menuElements.pathDigital} strokeDasharray="152,152" strokeDashoffset="0" fill="none" stroke="#00CCFF" strokeWidth="5" strokeMiterlimit="10" x1="62" y1="36.4" x2="168.84" y2="143.24"/>
			<path id="circle_digital_0" ref={menuElements.circleDigital0} strokeDasharray="13,13" fill="none" stroke="#00CCFF" strokeWidth="2.5" strokeMiterlimit="10" d="M168.84,143.24c1.562-1.563,4.094-1.562,5.656,0s1.563,4.094,0,5.657"/>
			<path id="circle_digital_1" ref={menuElements.circleDigital1} strokeDasharray="13,13" fill="none" stroke="#00CCFF" strokeWidth="2.5" strokeMiterlimit="10" d="M168.84,143.24c-1.562,1.562-1.562,4.095,0,5.657s4.094,1.562,5.656,0"/>
            <g id="text_digital">
				<path id="text_digital_d" ref={menuElements.textDigitalD} fill="#00CCFF" d="M186.037,164.268c-0.713,0.305-1.404,0.353-2.075,0.146c-0.672-0.207-1.383-0.687-2.134-1.438
					c-1.243-1.242-1.863-2.379-1.86-3.409c0.002-1.03,0.634-2.175,1.894-3.435l2.07-2.072c1.266-1.265,2.414-1.898,3.443-1.9
					c1.031-0.004,2.168,0.617,3.41,1.859c0.719,0.719,1.181,1.394,1.389,2.025c0.207,0.633,0.191,1.312-0.046,2.035l4.856-4.855
					l1.292,1.292l-12.339,12.339l-1.242-1.242L186.037,164.268z M188.291,161.915l1.426-1.425c0.911-0.912,1.361-1.785,1.351-2.619
					s-0.481-1.715-1.409-2.644c-0.846-0.845-1.559-1.255-2.142-1.23s-1.427,0.59-2.532,1.694l-1.49,1.492
					c-1.1,1.1-1.662,1.939-1.688,2.523c-0.024,0.582,0.386,1.296,1.23,2.141c0.929,0.929,1.81,1.398,2.645,1.41
					C186.516,163.268,187.385,162.821,188.291,161.915z"/>
				<path id="text_digital_i_0" ref={menuElements.textDigitalI0} fill="#00CCFF" d="M188.639,169.555l8.727-8.725l1.283,1.283l-8.725,8.727L188.639,169.555z M199.52,158.675
					l1.459-1.458l1.283,1.283l-1.458,1.459L199.52,158.675z"/>
				<path id="text_digital_g" ref={menuElements.textDigitalG} fill="#00CCFF" d="M208.626,172.091L200,180.717c-1.281,1.281-2.427,1.924-3.436,1.926
					c-1.008,0.004-2.145-0.627-3.409-1.893l-0.597-0.597c-1.139-1.138-1.762-2.177-1.873-3.116c-0.109-0.939,0.276-1.922,1.16-2.949
					l1.318,1.316c-0.504,0.613-0.719,1.175-0.646,1.684c0.071,0.508,0.486,1.141,1.242,1.896l0.58,0.58
					c0.763,0.763,1.432,1.183,2.006,1.26c0.574,0.078,1.143-0.166,1.707-0.729l1.807-1.807c-0.724,0.237-1.403,0.251-2.039,0.041
					c-0.635-0.21-1.314-0.677-2.038-1.4c-1.243-1.243-1.862-2.375-1.856-3.397s0.641-2.165,1.906-3.431l2.071-2.071
					c1.271-1.271,2.417-1.909,3.438-1.915c1.022-0.005,2.154,0.613,3.398,1.857c0.745,0.745,1.222,1.459,1.429,2.142
					c0.208,0.683,0.161,1.382-0.137,2.101l1.358-1.359L208.626,172.091z M202.262,175.886l1.426-1.425
					c0.911-0.912,1.361-1.785,1.351-2.619s-0.483-1.718-1.417-2.651c-0.846-0.845-1.563-1.257-2.154-1.235
					c-0.592,0.023-1.437,0.583-2.536,1.683l-1.491,1.491c-1.094,1.094-1.652,1.938-1.674,2.527c-0.021,0.592,0.39,1.31,1.234,2.154
					c0.934,0.934,1.818,1.406,2.652,1.418C200.486,177.24,201.355,176.791,202.262,175.886z"/>
				<path id="text_digital_i_1" ref={menuElements.textDigitalI1} fill="#00CCFF" d="M202.585,183.501l8.726-8.726l1.285,1.285l-8.727,8.725L202.585,183.501z M213.465,172.621
					l1.459-1.459l1.285,1.285l-1.459,1.458L213.465,172.621z"/>
				<path id="text_digital_t" ref={menuElements.textDigitalT} fill="#00CCFF" d="M213.308,178.977l1.103-1.102l1.068,1.068l1.964-1.964l1.293,1.293l-1.964,1.964l4.275,4.275
					l-1.102,1.103l-4.275-4.276l-4.807,4.807c-0.686,0.686-1.039,1.262-1.061,1.731c-0.022,0.47,0.281,1.019,0.912,1.649
					c0.624,0.624,1.164,0.926,1.619,0.907c0.456-0.02,1.035-0.38,1.736-1.081l0.257-0.257l1.31,1.31l-0.074,0.074
					c-1.227,1.227-2.256,1.849-3.087,1.868c-0.832,0.02-1.888-0.612-3.17-1.894c-1-1-1.47-1.92-1.409-2.76s0.68-1.848,1.856-3.024
					l4.624-4.624L213.308,178.977z"/>
				<path id="text_digital_a" ref={menuElements.textDigitalA} fill="#00CCFF" d="M221.611,200.688c-0.564,0.166-1.145,0.113-1.74-0.158c-0.597-0.271-1.279-0.79-2.047-1.558
					c-1.365-1.364-2.084-2.474-2.158-3.327c-0.075-0.854,0.418-1.811,1.479-2.871c0.613-0.613,1.147-1.04,1.604-1.28
					s0.912-0.341,1.371-0.302c0.387,0.032,0.82,0.215,1.301,0.547c0.48,0.33,1.103,0.878,1.865,1.641
					c0.789,0.789,1.328,1.458,1.615,2.005c0.287,0.546,0.384,1.077,0.29,1.591l1.509-1.508c0.712-0.713,1.035-1.313,0.969-1.799
					s-0.654-1.284-1.765-2.395c-0.807-0.807-1.427-1.258-1.86-1.354s-0.889,0.081-1.363,0.534l-1.284-1.284l0.198-0.198
					c0.746-0.746,1.52-1.037,2.32-0.871c0.802,0.166,1.793,0.84,2.975,2.021c0.924,0.924,1.586,1.664,1.989,2.222
					s0.636,1.038,0.696,1.441c0.055,0.454-0.012,0.878-0.199,1.276c-0.188,0.397-0.638,0.953-1.35,1.666l-6.107,6.107l-1.178-1.178
					L221.611,200.688z M222.846,199.321l0.92-0.92c0.414-0.415,0.507-0.93,0.277-1.546c-0.229-0.616-0.805-1.386-1.728-2.308
					c-0.845-0.846-1.517-1.304-2.014-1.376c-0.497-0.071-1.047,0.193-1.649,0.796c-0.685,0.685-0.991,1.293-0.92,1.822
					c0.072,0.531,0.562,1.249,1.467,2.154c0.813,0.813,1.531,1.336,2.159,1.571C221.985,199.749,222.48,199.684,222.846,199.321z"/>
				<path id="text_digital_l" ref={menuElements.textDigitalL} fill="#00CCFF" d="M224.512,205.428l12.338-12.34l1.285,1.285l-12.34,12.338L224.512,205.428z"/>
			</g>
            <line id="link_digital" ref={menuElements.linkDigital} opacity="0" fill="none" stroke="#000000" strokeWidth="40" strokeMiterlimit="10" strokeLinecap="round" x1="174.496" y1="148.896" x2="238.496" y2="212.896" pointerEvents="auto" onClick={() => {navigateMenu("/digital")}}/>

            <path id="path_games" ref={menuElements.pathGames} strokeDasharray="162,162" strokeDashoffset="0" fill="none" stroke="#CCFF33" strokeWidth="5" strokeMiterlimit="10" d="M62,62l30.464,30.464C94.409,94.409,96,98.25,96,101v41.2c0,2.75,1.591,6.591,3.536,8.536l41.192,41.192"/>
			<path id="circle_games_0" ref={menuElements.circleGames0} strokeDasharray="13,13" fill="none" stroke="#CCFF33" strokeWidth="2.5" strokeMiterlimit="10" d="M140.728,191.928c1.562-1.563,4.095-1.562,5.657,0s1.562,4.094,0,5.657"/>
			<path id="circle_games_1" ref={menuElements.circleGames1} strokeDasharray="13,13" fill="none" stroke="#CCFF33" strokeWidth="2.5" strokeMiterlimit="10" d="M140.728,191.928c-1.562,1.562-1.562,4.095,0,5.657s4.094,1.562,5.656,0"/>
            <g id="text_games">
                <path id="text_games_g" ref={menuElements.textGamesG} fill="#CCFF33" d="M166.715,206.77l-8.626,8.625c-1.282,1.282-2.426,1.925-3.436,1.928
		            c-1.008,0.002-2.145-0.629-3.409-1.896l-0.597-0.596c-1.138-1.139-1.763-2.177-1.873-3.116c-0.109-0.938,0.276-1.922,1.161-2.95
		            l1.317,1.318c-0.503,0.613-0.718,1.174-0.646,1.682c0.072,0.509,0.486,1.141,1.243,1.897l0.58,0.58
		            c0.763,0.763,1.431,1.183,2.006,1.26c0.574,0.077,1.144-0.165,1.707-0.729l1.808-1.807c-0.725,0.238-1.403,0.252-2.039,0.042
		            c-0.637-0.21-1.314-0.677-2.038-1.4c-1.243-1.243-1.862-2.376-1.856-3.397s0.642-2.166,1.906-3.431l2.071-2.071
		            c1.271-1.271,2.417-1.909,3.438-1.914c1.022-0.006,2.154,0.613,3.397,1.855c0.746,0.746,1.222,1.46,1.429,2.143
		            c0.207,0.682,0.162,1.383-0.137,2.101l1.358-1.358L166.715,206.77z M160.351,210.564l1.425-1.426
		            c0.912-0.911,1.362-1.784,1.352-2.618c-0.011-0.835-0.484-1.718-1.417-2.651c-0.846-0.846-1.563-1.257-2.154-1.234
		            c-0.591,0.021-1.436,0.582-2.536,1.682l-1.491,1.491c-1.094,1.095-1.651,1.937-1.674,2.527c-0.021,0.591,0.39,1.31,1.234,2.154
		            c0.934,0.934,1.816,1.406,2.652,1.417C158.575,211.918,159.445,211.471,160.351,210.564z"/>
                <path id="text_games_a" ref={menuElements.textGamesA} fill="#CCFF33" d="M167.361,223.027c-0.563,0.166-1.144,0.113-1.739-0.158c-0.597-0.271-1.278-0.789-2.046-1.558
		            c-1.364-1.364-2.084-2.474-2.159-3.327c-0.074-0.854,0.418-1.811,1.479-2.871c0.613-0.613,1.148-1.039,1.604-1.28
		            c0.456-0.24,0.913-0.341,1.372-0.302c0.387,0.033,0.819,0.215,1.301,0.547c0.48,0.331,1.102,0.878,1.864,1.641
		            c0.79,0.789,1.328,1.458,1.615,2.005c0.288,0.547,0.385,1.077,0.291,1.591l1.509-1.508c0.712-0.713,1.035-1.313,0.969-1.799
		            c-0.067-0.486-0.654-1.283-1.766-2.395c-0.806-0.807-1.427-1.258-1.859-1.354c-0.434-0.096-0.889,0.081-1.363,0.534l-1.285-1.284
		            l0.199-0.198c0.746-0.746,1.52-1.037,2.32-0.871s1.793,0.84,2.975,2.022c0.922,0.923,1.585,1.663,1.988,2.221
		            c0.404,0.558,0.635,1.038,0.697,1.442c0.055,0.453-0.012,0.878-0.199,1.275c-0.188,0.398-0.639,0.953-1.352,1.666l-6.106,6.107
		            l-1.177-1.178L167.361,223.027z M168.596,221.66l0.92-0.92c0.415-0.414,0.507-0.93,0.277-1.546
		            c-0.229-0.616-0.806-1.386-1.729-2.308c-0.845-0.846-1.517-1.304-2.013-1.375c-0.498-0.072-1.047,0.193-1.649,0.795
		            c-0.685,0.686-0.991,1.293-0.92,1.822c0.072,0.531,0.562,1.249,1.468,2.155c0.812,0.812,1.53,1.335,2.157,1.57
		            C167.736,222.089,168.232,222.024,168.596,221.66z"/>
                <path id="text_games_m" ref={menuElements.textGamesM} fill="#CCFF33" d="M170.444,227.949l8.727-8.726l1.26,1.26l-1.193,1.192c0.63-0.265,1.266-0.289,1.906-0.074
		            c0.642,0.216,1.313,0.674,2.015,1.376c0.739,0.74,1.204,1.433,1.396,2.075c0.19,0.646,0.134,1.322-0.17,2.035
		            c0.763-0.255,1.466-0.286,2.108-0.096c0.645,0.19,1.303,0.623,1.977,1.297c1.145,1.144,1.71,2.162,1.699,3.058
		            c-0.013,0.895-0.605,1.931-1.781,3.107l-5.719,5.718l-1.292-1.293l5.312-5.312c0.974-0.972,1.483-1.735,1.533-2.287
		            c0.05-0.552-0.291-1.193-1.02-1.923c-0.808-0.807-1.601-1.188-2.379-1.143c-0.778,0.043-1.623,0.521-2.535,1.434l-5.071,5.07
		            l-1.251-1.25l5.908-5.908c0.69-0.691,1.03-1.284,1.022-1.777c-0.008-0.495-0.366-1.096-1.073-1.803
		            c-0.828-0.829-1.646-1.229-2.456-1.197c-0.81,0.029-1.661,0.492-2.557,1.388l-5.071,5.071L170.444,227.949z"/>
                <path id="text_games_e" ref={menuElements.textGamesE} fill="#CCFF33" d="M188.302,246.039c-1.304-1.305-1.962-2.47-1.973-3.498c-0.012-1.027,0.622-2.179,1.896-3.455
		            l2.072-2.071c1.281-1.282,2.435-1.919,3.459-1.91c1.025,0.009,2.187,0.661,3.484,1.96l0.604,0.604
		            c1.304,1.304,1.961,2.47,1.973,3.497c0.011,1.027-0.625,2.182-1.905,3.464l-1.318,1.317l-6.281-6.265
		            c-0.094,0.083-0.194,0.177-0.307,0.282c-0.109,0.104-0.262,0.254-0.455,0.447c-0.9,0.9-1.361,1.647-1.379,2.241
		            c-0.021,0.594,0.406,1.327,1.279,2.2l0.604,0.604c0.779,0.779,1.432,1.208,1.959,1.289c0.527,0.08,1.096-0.134,1.703-0.642
		            l1.342,1.342c-1.01,0.878-1.996,1.257-2.957,1.135c-0.961-0.121-2.026-0.768-3.199-1.939L188.302,246.039z M191.459,238.539
		            l4.938,4.939l0.34-0.34c0.817-0.818,1.227-1.52,1.227-2.105s-0.435-1.312-1.301-2.18l-0.604-0.604
		            c-0.862-0.862-1.583-1.291-2.163-1.285s-1.278,0.417-2.096,1.234L191.459,238.539z"/>
                <path id="text_games_s" ref={menuElements.textGamesS} fill="#CCFF33" d="M197.118,249.436l1.31,1.31l-0.199,0.198c-0.536,0.537-0.737,1.071-0.608,1.604
		            c0.13,0.532,0.694,1.3,1.694,2.299c1.121,1.122,1.914,1.71,2.378,1.765c0.465,0.057,0.989-0.209,1.575-0.795
		            c0.568-0.568,0.842-1.055,0.819-1.459c-0.022-0.402-0.438-1.052-1.243-1.947c-0.298-0.33-0.707-0.773-1.227-1.325
		            c-1.139-1.204-1.755-1.98-1.849-2.329c-0.147-0.514-0.137-0.998,0.037-1.454c0.175-0.456,0.557-0.979,1.148-1.569
		            c0.967-0.967,1.866-1.376,2.7-1.227c0.834,0.148,2.089,1.061,3.763,2.734c1.299,1.298,2.016,2.341,2.149,3.128
		            c0.135,0.787-0.205,1.59-1.022,2.407l-1.293-1.293l0.084-0.083c0.387-0.387,0.484-0.819,0.298-1.3
		            c-0.188-0.481-0.778-1.219-1.773-2.213c-0.961-0.961-1.663-1.486-2.104-1.574c-0.441-0.089-0.945,0.148-1.508,0.712
		            c-0.345,0.343-0.56,0.608-0.646,0.796c-0.087,0.188-0.104,0.381-0.051,0.58c0.123,0.397,0.617,1.052,1.484,1.964
		            c0.221,0.231,0.389,0.411,0.505,0.538c0.702,0.757,1.234,1.366,1.604,1.828c0.367,0.461,0.6,0.821,0.692,1.081
		            c0.209,0.563,0.229,1.093,0.062,1.587c-0.168,0.494-0.566,1.057-1.196,1.686c-0.642,0.641-1.207,1.047-1.698,1.219
		            c-0.492,0.171-1.072,0.176-1.74,0.016c-0.31-0.066-0.703-0.285-1.182-0.658c-0.478-0.373-1.096-0.938-1.853-1.695
		            c-1.547-1.546-2.392-2.748-2.535-3.604c-0.145-0.856,0.254-1.754,1.193-2.693L197.118,249.436z"/>
            </g>
            <line id="link_games" ref={menuElements.linkGames} opacity="0" fill="none" stroke="#000000" strokeWidth="40" strokeMiterlimit="10" strokeLinecap="round" x1="146.385" y1="197.584" x2="210.385" y2="261.584" pointerEvents="auto" onClick={() => {navigateMenu("/games")}}/>

            <path id="path_design" ref={menuElements.pathDesign} strokeDasharray="210,210" strokeDashoffset="0" fill="none" stroke="#FFFF00" strokeWidth="5" strokeMiterlimit="10" d="M36.4,62l24.064,24.064C62.409,88.009,64,91.85,64,94.6V187c0,2.75,1.591,6.591,3.536,8.536l45.082,45.082"/>
			<path id="circle_design_0" ref={menuElements.circleDesign0} strokeDasharray="13,13" fill="none" stroke="#FFFF00" strokeWidth="2.5" strokeMiterlimit="10" d="M112.617,240.617c1.562-1.563,4.095-1.562,5.657,0s1.562,4.094,0,5.657"/>
			<path id="circle_design_1" ref={menuElements.circleDesign1} strokeDasharray="13,13" fill="none" stroke="#FFFF00" strokeWidth="2.5" strokeMiterlimit="10" d="M112.617,240.617c-1.562,1.562-1.562,4.095,0,5.657s4.094,1.562,5.656,0"/>
            <g id="text_design">
                <path id="text_design_d" ref={menuElements.textDesignD} fill="#FFFF00" d="M130.035,261.602c-0.714,0.305-1.405,0.353-2.076,0.146c-0.672-0.207-1.383-0.687-2.134-1.438
		            c-1.243-1.242-1.863-2.379-1.86-3.409c0.002-1.03,0.633-2.175,1.893-3.435l2.071-2.072c1.265-1.265,2.413-1.898,3.443-1.9
		            c1.029-0.004,2.167,0.617,3.41,1.859c0.718,0.719,1.181,1.394,1.388,2.025c0.207,0.633,0.191,1.312-0.045,2.035l4.855-4.855
		            l1.292,1.292l-12.339,12.339l-1.243-1.242L130.035,261.602z M132.289,259.249l1.425-1.425c0.912-0.912,1.362-1.785,1.351-2.619
		            c-0.011-0.834-0.48-1.715-1.409-2.644c-0.845-0.845-1.559-1.255-2.142-1.23c-0.583,0.024-1.427,0.59-2.532,1.694l-1.49,1.492
		            c-1.101,1.1-1.662,1.939-1.688,2.523c-0.025,0.582,0.385,1.296,1.23,2.141c0.928,0.929,1.809,1.398,2.644,1.41
		            C130.513,260.602,131.383,260.154,132.289,259.249z"/>
                <path id="text_design_e" ref={menuElements.textDesignE} fill="#FFFF00" d="M135.678,270.162c-1.304-1.305-1.961-2.469-1.972-3.497c-0.012-1.027,0.622-2.179,1.897-3.456
		            l2.071-2.07c1.282-1.282,2.435-1.919,3.46-1.91c1.025,0.008,2.186,0.661,3.484,1.959l0.604,0.605
		            c1.304,1.303,1.961,2.469,1.973,3.496c0.012,1.027-0.625,2.183-1.905,3.465l-1.317,1.316l-6.281-6.264
		            c-0.094,0.082-0.195,0.176-0.307,0.281s-0.262,0.254-0.456,0.447c-0.901,0.9-1.361,1.648-1.379,2.242
		            c-0.021,0.594,0.407,1.326,1.28,2.199l0.604,0.605c0.779,0.779,1.433,1.208,1.96,1.289c0.527,0.08,1.095-0.135,1.703-0.643
		            l1.342,1.342c-1.011,0.879-1.997,1.258-2.958,1.135c-0.961-0.121-2.026-0.768-3.198-1.938L135.678,270.162z M138.835,262.662
		            l4.938,4.939l0.34-0.34c0.817-0.817,1.227-1.52,1.227-2.104c0-0.584-0.435-1.312-1.301-2.179l-0.604-0.604
		            c-0.862-0.861-1.583-1.29-2.163-1.285c-0.581,0.006-1.278,0.418-2.097,1.234L138.835,262.662z"/>
                <path id="text_design_s" ref={menuElements.textDesignS} fill="#FFFF00" d="M144.495,273.56l1.309,1.31l-0.199,0.198c-0.536,0.536-0.738,1.071-0.608,1.604
		            c0.13,0.533,0.694,1.3,1.694,2.3c1.122,1.121,1.914,1.709,2.378,1.765c0.465,0.056,0.989-0.21,1.575-0.796
		            c0.569-0.568,0.842-1.055,0.82-1.458c-0.022-0.403-0.437-1.052-1.243-1.947c-0.298-0.331-0.707-0.773-1.226-1.325
		            c-1.139-1.205-1.754-1.98-1.849-2.329c-0.147-0.514-0.136-0.999,0.038-1.454c0.174-0.456,0.558-0.979,1.148-1.57
		            c0.967-0.967,1.867-1.375,2.701-1.227c0.834,0.149,2.088,1.061,3.762,2.734c1.298,1.299,2.015,2.342,2.15,3.129
		            c0.136,0.787-0.205,1.59-1.022,2.406l-1.293-1.293l0.083-0.082c0.387-0.387,0.486-0.82,0.298-1.301
		            c-0.188-0.48-0.779-1.219-1.773-2.213c-0.961-0.961-1.662-1.486-2.104-1.574s-0.945,0.148-1.508,0.713
		            c-0.343,0.342-0.559,0.607-0.646,0.795s-0.104,0.381-0.05,0.58c0.122,0.398,0.616,1.053,1.483,1.965
		            c0.221,0.231,0.39,0.41,0.505,0.538c0.702,0.757,1.235,1.366,1.604,1.827c0.367,0.461,0.599,0.822,0.692,1.082
		            c0.21,0.563,0.229,1.092,0.062,1.586s-0.566,1.057-1.196,1.687c-0.642,0.641-1.207,1.047-1.699,1.218
		            c-0.491,0.172-1.072,0.177-1.74,0.017c-0.31-0.066-0.704-0.286-1.181-0.659c-0.478-0.373-1.095-0.938-1.853-1.694
		            c-1.547-1.547-2.392-2.748-2.536-3.604c-0.144-0.855,0.255-1.754,1.193-2.693L144.495,273.56z"/>
                <path id="text_design_i" ref={menuElements.textDesignI} fill="#FFFF00" d="M152.118,286.371l8.726-8.727l1.284,1.285l-8.727,8.725L152.118,286.371z M162.999,275.49
		            l1.458-1.459l1.284,1.285l-1.458,1.458L162.999,275.49z"/>
                <path id="text_design_g" ref={menuElements.textDesignG} fill="#FFFF00" d="M172.105,288.906l-8.626,8.626c-1.282,1.282-2.427,1.924-3.435,1.927
		            c-1.008,0.002-2.146-0.629-3.41-1.894l-0.597-0.597c-1.139-1.139-1.763-2.176-1.873-3.115c-0.111-0.939,0.275-1.923,1.16-2.951
		            l1.316,1.318c-0.503,0.613-0.718,1.174-0.646,1.682c0.071,0.509,0.485,1.141,1.242,1.898l0.58,0.58
		            c0.763,0.762,1.432,1.182,2.005,1.259c0.575,0.077,1.144-0.165,1.707-0.729l1.808-1.807c-0.725,0.238-1.403,0.252-2.039,0.042
		            c-0.635-0.21-1.314-0.677-2.038-1.4c-1.243-1.243-1.862-2.376-1.856-3.397c0.007-1.021,0.642-2.166,1.906-3.431l2.071-2.071
		            c1.271-1.271,2.417-1.909,3.438-1.914c1.022-0.006,2.154,0.613,3.397,1.855c0.746,0.746,1.222,1.46,1.429,2.143
		            c0.208,0.682,0.162,1.383-0.137,2.101l1.359-1.358L172.105,288.906z M165.741,292.701l1.425-1.426
		            c0.912-0.911,1.361-1.783,1.351-2.618c-0.011-0.834-0.482-1.718-1.417-2.651c-0.845-0.846-1.563-1.257-2.155-1.234
		            c-0.591,0.021-1.436,0.582-2.535,1.682l-1.491,1.491c-1.094,1.095-1.652,1.937-1.674,2.527c-0.022,0.59,0.39,1.31,1.234,2.155
		            c0.935,0.934,1.817,1.406,2.651,1.416C163.965,294.055,164.835,293.607,165.741,292.701z"/>
                <path id="text_design_n" ref={menuElements.textDesignN} fill="#FFFF00" d="M166.064,300.316l8.727-8.726l1.234,1.235l-1.192,1.192c0.656-0.237,1.323-0.23,2.001,0.021
		            c0.677,0.252,1.396,0.758,2.159,1.521c1.144,1.143,1.714,2.174,1.711,3.094s-0.584,1.961-1.744,3.121l-5.718,5.717l-1.294-1.293
		            l5.444-5.443c0.912-0.912,1.377-1.645,1.396-2.201c0.02-0.555-0.358-1.219-1.131-1.992c-0.911-0.911-1.787-1.37-2.627-1.375
		            c-0.84-0.006-1.709,0.441-2.609,1.342l-5.071,5.072L166.064,300.316z"/>
            </g>
            <line id="link_design" ref={menuElements.linkDesign} opacity="0" fill="none" stroke="#000000" strokeWidth="40" strokeMiterlimit="10" strokeLinecap="round" x1="118.274" y1="246.273" x2="182.273" y2="310.272" pointerEvents="auto" onClick={() => {navigateMenu("/design")}}/>

            <path id="path_store" ref={menuElements.pathStore} strokeDasharray="257,257" strokeDashoffset="0" fill="none" stroke="#FFFFFF" strokeWidth="5" strokeMiterlimit="10" d="M10.8,62l17.665,17.664C30.409,81.609,32,85.45,32,88.2v143.6c0,2.75,1.591,6.591,3.536,8.536l48.97,48.97"/>
			<path id="circle_store_0" ref={menuElements.circleStore0} strokeDasharray="13,13" fill="none" stroke="#FFFFFF" strokeWidth="2.5" strokeMiterlimit="10" d="M84.506,289.306c1.563-1.563,4.095-1.562,5.657,0s1.562,4.094,0,5.657"/>
			<path id="circle_store_1" ref={menuElements.circleStore1} strokeDasharray="13,13" fill="none" stroke="#FFFFFF" strokeWidth="2.5" strokeMiterlimit="10" d="M84.506,289.306c-1.562,1.562-1.562,4.095,0,5.657s4.094,1.562,5.656,0"/>
            <g id="text_store">
                <path id="text_store_s" ref={menuElements.textStoreS} fill="#FFFFFF" d="M97.122,302.867l1.31,1.311l-0.199,0.198c-0.536,0.536-0.738,1.07-0.608,1.604
		            s0.694,1.3,1.694,2.3c1.122,1.121,1.914,1.709,2.378,1.765c0.464,0.056,0.988-0.21,1.574-0.796c0.569-0.568,0.842-1.055,0.82-1.458
		            c-0.022-0.403-0.438-1.053-1.243-1.947c-0.298-0.331-0.707-0.773-1.227-1.326c-1.139-1.204-1.754-1.98-1.849-2.328
		            c-0.148-0.515-0.137-0.999,0.038-1.454c0.174-0.457,0.557-0.979,1.147-1.57c0.967-0.967,1.867-1.375,2.701-1.227
		            s2.088,1.061,3.762,2.734c1.298,1.299,2.015,2.341,2.149,3.128c0.136,0.788-0.205,1.59-1.022,2.407l-1.292-1.293l0.083-0.082
		            c0.387-0.387,0.486-0.82,0.298-1.301c-0.188-0.48-0.779-1.219-1.773-2.213c-0.961-0.961-1.662-1.486-2.104-1.574
		            c-0.442-0.089-0.944,0.148-1.508,0.713c-0.343,0.342-0.56,0.607-0.647,0.795s-0.104,0.381-0.05,0.58
		            c0.122,0.397,0.617,1.053,1.483,1.964c0.221,0.231,0.39,0.411,0.505,0.538c0.702,0.758,1.235,1.367,1.604,1.828
		            s0.599,0.821,0.692,1.081c0.21,0.563,0.23,1.093,0.063,1.587c-0.169,0.494-0.567,1.057-1.197,1.686
		            c-0.642,0.641-1.207,1.047-1.699,1.219c-0.491,0.171-1.071,0.177-1.74,0.016c-0.309-0.065-0.703-0.285-1.181-0.658
		            c-0.479-0.373-1.096-0.938-1.853-1.694c-1.547-1.547-2.392-2.749-2.536-3.604c-0.144-0.856,0.255-1.754,1.193-2.693L97.122,302.867
		            z"/>
                <path id="text_store_t" ref={menuElements.textStoreT} fill="#FFFFFF" d="M111.499,307.186l1.102-1.103l1.069,1.069l1.964-1.965l1.293,1.293l-1.964,1.965l4.275,4.275
		            l-1.102,1.102l-4.276-4.275l-4.806,4.807c-0.685,0.685-1.039,1.262-1.061,1.73c-0.022,0.471,0.281,1.02,0.911,1.649
		            c0.625,0.624,1.164,0.927,1.62,0.907s1.035-0.38,1.736-1.081l0.257-0.257l1.311,1.31l-0.074,0.074
		            c-1.228,1.227-2.255,1.849-3.086,1.868c-0.832,0.02-1.888-0.611-3.17-1.893c-1-1-1.471-1.92-1.409-2.76
		            c0.061-0.84,0.679-1.848,1.855-3.025l4.624-4.623L111.499,307.186z"/>
                <path id="text_store_o" ref={menuElements.textStoreO} fill="#FFFFFF" d="M116.156,327.32c-1.304-1.303-1.961-2.469-1.972-3.496c-0.011-1.027,0.622-2.18,1.898-3.455
		            l2.07-2.072c1.282-1.281,2.436-1.918,3.459-1.91c1.025,0.008,2.188,0.662,3.484,1.96l0.605,0.604
		            c1.304,1.305,1.961,2.47,1.973,3.498c0.011,1.027-0.625,2.182-1.906,3.463l-2.071,2.072c-1.281,1.281-2.434,1.916-3.455,1.905
		            c-1.022-0.011-2.183-0.665-3.48-1.964L116.156,327.32z M122.653,326.359l1.491-1.491c1.101-1.1,1.661-1.938,1.683-2.519
		            c0.021-0.58-0.414-1.318-1.31-2.213l-0.604-0.605c-0.89-0.889-1.62-1.321-2.191-1.297c-0.572,0.025-1.41,0.59-2.515,1.695
		            l-1.491,1.491c-1.111,1.11-1.677,1.953-1.699,2.528c-0.021,0.574,0.409,1.303,1.292,2.187l0.605,0.604
		            c0.89,0.891,1.627,1.324,2.212,1.302S121.553,327.459,122.653,326.359z"/>
                <path id="text_store_r" ref={menuElements.textStoreR} fill="#FFFFFF" d="M122.686,333.619l8.727-8.726l1.251,1.251l-1.193,1.193c0.635-0.271,1.251-0.318,1.848-0.141
		            c0.598,0.176,1.216,0.585,1.856,1.227c0.95,0.949,1.388,1.846,1.313,2.688c-0.074,0.843-0.693,1.847-1.859,3.013l-0.066,0.066
		            l-1.301-1.302l0.257-0.257c0.696-0.696,1.054-1.275,1.073-1.736s-0.277-0.998-0.891-1.611c-0.719-0.719-1.441-1.035-2.167-0.949
		            c-0.728,0.086-1.552,0.59-2.475,1.513l-5.071,5.071L122.686,333.619z"/>
                <path id="text_store_e" ref={menuElements.textStoreE} fill="#FFFFFF" d="M133.682,344.847c-1.304-1.304-1.961-2.47-1.972-3.497c-0.011-1.027,0.622-2.179,1.897-3.455
		            l2.071-2.071c1.282-1.282,2.435-1.919,3.459-1.911c1.025,0.009,2.188,0.662,3.484,1.961l0.604,0.604
		            c1.304,1.304,1.961,2.47,1.973,3.497c0.011,1.027-0.624,2.182-1.906,3.464l-1.317,1.317l-6.281-6.265
		            c-0.094,0.083-0.195,0.177-0.307,0.282c-0.11,0.104-0.262,0.254-0.455,0.447c-0.901,0.9-1.361,1.647-1.38,2.24
		            c-0.019,0.595,0.408,1.328,1.28,2.201l0.604,0.604c0.779,0.778,1.433,1.208,1.959,1.288c0.528,0.081,1.097-0.133,1.703-0.642
		            l1.343,1.343c-1.011,0.878-1.997,1.257-2.958,1.135c-0.961-0.121-2.026-0.768-3.198-1.939L133.682,344.847z M136.839,337.348
		            l4.938,4.938l0.341-0.34c0.816-0.817,1.227-1.519,1.227-2.104c0-0.586-0.434-1.313-1.301-2.18l-0.605-0.604
		            c-0.861-0.862-1.583-1.29-2.163-1.284s-1.278,0.417-2.096,1.234L136.839,337.348z"/>
            </g>
            <line id="link_store" ref={menuElements.linkStore} opacity="0" fill="none" stroke="#000000" strokeWidth="40" strokeMiterlimit="10" strokeLinecap="round" x1="90.163" y1="294.963" x2="154.163" y2="358.963" pointerEvents="auto" onClick={() => {navigateMenu("/store")}}/>

            <polygon id="start_store" fill="#FFFFFF" points="9.265,64 -10,44.735 -10,37.665 16.334,64 "/>
            <polygon id="start_design" fill="#FFFF00" points="34.865,64 -10,19.136 -10,12.065 41.934,64 "/>
            <polygon id="start_games" fill="#CCFF33" points="60.464,64 -10,-6.465 -10,-10 -6.464,-10 64,60.464 64,64 "/>
            <polygon id="start_digital" fill="#00CCFF" points="64,41.934 12.066,-10 19.137,-10 64,34.864 "/>
            <polygon id="start_home" fill="#FFFFFF" points="64,16.335 37.665,-10 44.736,-10 64,9.264 "/>

            <polygon id="menu_button_main" ref={menuElements.buttonMenu} opacity="0" fill="#000000" points="0,0 0,64 64,64 64,0 " display="ja" pointerEvents="auto" onClick={() => {handleMenuClick()}}/>

        </svg>

    };

	const menu_bg = {
		svg:

		<svg version="1.1" id="menu_bg_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 420 420" preserveAspectRatio="xMinYMin meet" pointerEvents="none">

			<path id="menu_bg" ref={menuElements.menuBg} fill="#000000" opacity="0.75" pointerEvents="auto" d="M0,0v405.096l154.892-0.002c3.851,0,8.576-2.729,10.5-6.063l10.557-18.283c1.924-3.334,5.074-8.789,7-12.125l130.054-225.259c1.925-3.334,1.272-8.29-1.45-11.012L179.2,0L0,0z"/>

		</svg>

	};

	const logo = {
        svg:

        <svg version="1.1" id="logo_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 320.25 140" preserveAspectRatio="xMaxYMin meet" pointerEvents="none">

			<path id="logo_flag" fill={logoFlagFillState} d="M0,0l69.05,69.05C71.772,71.771,77.15,74,81,74h185.399c3.85,0,8.575-2.729,10.5-6.063L316.123,0H0z"/>
			<path id="logo_n0" fill="#FFFFFF" d="M103.913,52.168l-0.002-17.038h2.408l0.002,2.33c0.405-0.875,1.065-1.519,1.976-1.936
				c0.906-0.414,2.103-0.622,3.593-0.623c2.235,0,3.799,0.451,4.694,1.351c0.896,0.902,1.34,2.486,1.346,4.753l-0.007,11.163h-2.521
				v-10.63c0.001-1.782-0.26-2.952-0.784-3.512c-0.526-0.562-1.541-0.84-3.054-0.842c-1.782,0.002-3.083,0.41-3.909,1.224
				c-0.824,0.815-1.237,2.101-1.235,3.859l-0.001,9.9L103.913,52.168L103.913,52.168z"/>
			<path id="logo_j" fill="#FFFFFF" d="M126.044,54.66c0.001,1.349-0.281,2.354-0.854,3.021c-0.572,0.672-1.438,1.008-2.587,1.008
				c-0.153,0-0.332-0.008-0.529-0.027c-0.2-0.016-0.414-0.038-0.652-0.071l-0.02-1.991h0.875c0.464,0,0.786-0.19,0.962-0.574
				c0.183-0.383,0.268-1.293,0.267-2.729l0.001-18.172l2.537,0.001V54.66L126.044,54.66z M123.512,30.919l-0.007-2.848l2.543,0.001
				l-0.003,2.848L123.512,30.919z"/>
			<path id="logo_b" fill="#FFFFFF" d="M131.26,52.168V28.071l2.509-0.002l0.003,9.488c0.481-0.939,1.143-1.619,1.965-2.033
				c0.825-0.415,1.937-0.622,3.34-0.622c2.431-0.001,4.146,0.501,5.136,1.506c1.002,1.002,1.5,2.743,1.5,5.227v4.046
				c-0.003,2.471-0.5,4.203-1.5,5.209c-0.988,1.002-2.704,1.504-5.135,1.504c-1.464,0-2.632-0.229-3.496-0.688
				c-0.866-0.459-1.5-1.177-1.91-2.158v2.621L131.26,52.168L131.26,52.168z M133.768,42.263l0.001,2.784
				c0.001,1.771,0.411,3.057,1.239,3.859c0.824,0.804,2.146,1.205,3.969,1.205c1.654,0,2.746-0.295,3.296-0.889
				c0.544-0.598,0.815-1.968,0.813-4.113l0.004-2.912c-0.002-2.159-0.275-3.534-0.817-4.13c-0.55-0.594-1.645-0.89-3.292-0.89
				c-1.823,0-3.15,0.403-3.973,1.207C134.18,39.19,133.769,40.484,133.768,42.263z"/>
			<path id="logo_d" fill="#FFFFFF" d="M172.781,49.544c-0.401,0.992-1.033,1.715-1.884,2.168c-0.862,0.456-2.021,0.681-3.49,0.681
				c-2.422,0-4.14-0.506-5.141-1.515c-1.002-1.007-1.504-2.741-1.508-5.202l-0.002-4.046c0.001-2.469,0.507-4.206,1.501-5.217
				c1.01-1.008,2.728-1.516,5.149-1.513c1.4,0,2.516,0.206,3.336,0.623c0.818,0.412,1.464,1.092,1.941,2.029l-0.005-9.481l2.528,0.001
				l-0.004,24.09h-2.426L172.781,49.544L172.781,49.544z M172.688,45.047v-2.781c-0.002-1.781-0.415-3.075-1.241-3.879
				c-0.825-0.8-2.144-1.205-3.959-1.204c-1.649-0.001-2.749,0.295-3.289,0.892c-0.546,0.592-0.823,1.967-0.821,4.126l-0.002,2.913
				c0.001,2.146,0.277,3.517,0.823,4.11c0.54,0.594,1.64,0.892,3.289,0.892c1.813,0,3.134-0.404,3.958-1.208
				C172.271,48.104,172.686,46.815,172.688,45.047z"/>
			<path id="logo_e" fill="#FFFFFF" d="M186.65,52.393c-2.547-0.002-4.327-0.496-5.341-1.485c-1.017-0.992-1.521-2.735-1.521-5.229v-4.043
				c-0.001-2.502,0.506-4.253,1.517-5.243c1.006-0.994,2.774-1.488,5.313-1.487h1.183c2.549,0.001,4.328,0.497,5.343,1.487
				c1.012,0.994,1.518,2.741,1.518,5.244v2.567l-12.249,0.021c-0.014,0.172-0.015,0.363-0.022,0.573
				c-0.008,0.214-0.007,0.505-0.01,0.88c0.002,1.758,0.279,2.94,0.844,3.535c0.558,0.603,1.689,0.902,3.396,0.902h1.18
				c1.521,0,2.579-0.224,3.172-0.659c0.599-0.438,0.938-1.199,1.036-2.287l2.62-0.002c-0.133,1.843-0.722,3.177-1.779,3.997
				c-1.057,0.821-2.728,1.229-5.018,1.229H186.65L186.65,52.393z M182.409,41.989h9.646l-0.002-0.665c0-1.598-0.285-2.681-0.854-3.251
				c-0.576-0.574-1.711-0.861-3.401-0.86h-1.186c-1.678,0-2.803,0.287-3.364,0.86c-0.562,0.571-0.84,1.651-0.838,3.25L182.409,41.989
				L182.409,41.989z"/>
			<path id="logo_s" fill="#FFFFFF" d="M198.573,47.103l2.559-0.002l0.001,0.391c0,1.044,0.32,1.764,0.97,2.158
				c0.648,0.394,1.944,0.59,3.901,0.592c2.19-0.002,3.536-0.201,4.046-0.604c0.507-0.396,0.762-1.166,0.762-2.313
				c0.003-1.111-0.21-1.854-0.621-2.223c-0.418-0.376-1.455-0.604-3.116-0.69c-0.615-0.032-1.446-0.063-2.492-0.097
				c-2.287-0.063-3.643-0.22-4.08-0.469c-0.647-0.356-1.103-0.839-1.383-1.454c-0.272-0.616-0.409-1.502-0.41-2.658
				c0.001-1.886,0.484-3.166,1.438-3.836c0.962-0.667,3.077-0.999,6.346-1.002c2.533,0,4.256,0.319,5.157,0.954
				c0.896,0.639,1.353,1.755,1.351,3.35h-2.522l0.001-0.162c0-0.755-0.332-1.274-0.984-1.561c-0.653-0.286-1.946-0.428-3.891-0.428
				c-1.88,0-3.072,0.172-3.596,0.517c-0.517,0.344-0.771,1.066-0.771,2.171c-0.003,0.667,0.044,1.137,0.144,1.408
				c0.098,0.267,0.271,0.474,0.52,0.612c0.503,0.271,1.626,0.427,3.369,0.471c0.436,0.013,0.776,0.021,1.017,0.032
				c1.421,0.053,2.54,0.128,3.35,0.217c0.808,0.092,1.388,0.217,1.729,0.379c0.756,0.346,1.29,0.842,1.609,1.485
				c0.317,0.651,0.478,1.587,0.479,2.819c0,1.251-0.154,2.199-0.473,2.846c-0.313,0.651-0.872,1.225-1.683,1.719
				c-0.365,0.237-0.966,0.404-1.802,0.506c-0.826,0.105-1.981,0.158-3.456,0.155c-3.021,0-5.021-0.349-5.994-1.044
				c-0.979-0.697-1.469-1.96-1.467-3.796v-0.452l0,0v0.007L198.573,47.103L198.573,47.103z"/>
			<path id="logo_i" fill="#FFFFFF" d="M218.524,30.918l0.003-2.848l2.509-0.001l0.002,2.85L218.524,30.918z M218.527,52.166V35.13l2.512-0.003l-0.001,17.041L218.527,52.166z"/>
			<path id="logo_g" fill="#FFFFFF" d="M240.52,35.126v16.847c0.003,2.502-0.492,4.248-1.479,5.234c-0.979,0.986-2.705,1.482-5.176,1.482h-1.162
				c-2.226,0-3.847-0.406-4.868-1.217c-1.025-0.809-1.604-2.145-1.748-4.013h2.569c0.112,1.089,0.447,1.845,1.009,2.271
				c0.571,0.428,1.599,0.64,3.07,0.64l1.134,0.003c1.486-0.003,2.548-0.247,3.188-0.732c0.633-0.484,0.956-1.274,0.956-2.377v-3.525
				c-0.477,0.937-1.132,1.617-1.954,2.031c-0.823,0.416-1.94,0.623-3.359,0.623c-2.42,0-4.132-0.504-5.126-1.509
				c-0.99-1.003-1.487-2.737-1.485-5.21l-0.002-4.042c0.001-2.485,0.499-4.227,1.487-5.229c0.994-1.003,2.701-1.507,5.13-1.508
				c1.459,0.001,2.617,0.234,3.489,0.7c0.869,0.464,1.507,1.19,1.915,2.182v-2.653L240.52,35.126L240.52,35.126z M238.014,45.047
				l-0.001-2.784c0.001-1.781-0.415-3.072-1.234-3.876c-0.829-0.801-2.156-1.207-3.979-1.205c-1.648,0-2.753,0.299-3.308,0.899
				c-0.559,0.598-0.838,1.971-0.835,4.118v2.912c0,2.138,0.277,3.504,0.835,4.103c0.555,0.601,1.655,0.896,3.309,0.897
				c1.823-0.002,3.149-0.402,3.979-1.207C237.597,48.102,238.014,46.813,238.014,45.047z"/>
			<path id="logo_n1" fill="#FFFFFF" d="M245.758,52.166l0.001-17.039h2.41l-0.001,2.331c0.411-0.873,1.066-1.519,1.979-1.935
				c0.904-0.413,2.104-0.622,3.59-0.622c2.239,0,3.801,0.451,4.696,1.351c0.896,0.901,1.347,2.485,1.342,4.752v11.164h-2.521
				l-0.006-10.631c0.006-1.781-0.26-2.952-0.781-3.509c-0.521-0.563-1.536-0.844-3.055-0.844c-1.78,0.002-3.082,0.41-3.903,1.224
				c-0.828,0.814-1.235,2.101-1.236,3.861l-0.001,9.898l-2.513,0.003V52.166L245.758,52.166z"/>
			<path id="logo_reg" fill="#FFFFFF" d="M266.444,28.394c0.501,0,0.972,0.091,1.414,0.273c0.438,0.181,0.829,0.447,1.171,0.794
				c0.337,0.337,0.595,0.725,0.77,1.162c0.183,0.443,0.272,0.905,0.273,1.397c-0.001,0.494-0.091,0.959-0.265,1.388
				c-0.178,0.432-0.44,0.815-0.775,1.152c-0.354,0.35-0.758,0.618-1.2,0.804c-0.447,0.187-0.905,0.277-1.385,0.277
				c-0.483,0-0.942-0.09-1.381-0.275c-0.438-0.181-0.825-0.449-1.169-0.798c-0.347-0.343-0.609-0.735-0.794-1.17
				c-0.181-0.441-0.271-0.9-0.271-1.382c0-0.473,0.092-0.933,0.276-1.382c0.185-0.446,0.456-0.847,0.803-1.197
				c0.335-0.341,0.716-0.599,1.147-0.779C265.495,28.485,265.955,28.396,266.444,28.394z M269.673,32.033
				c0-0.439-0.075-0.857-0.238-1.25c-0.152-0.39-0.378-0.736-0.683-1.036c-0.311-0.305-0.664-0.536-1.054-0.702
				c-0.394-0.165-0.813-0.246-1.255-0.247c-0.424,0-0.835,0.083-1.217,0.239c-0.388,0.157-0.73,0.39-1.033,0.69
				c-0.313,0.321-0.552,0.679-0.718,1.07c-0.171,0.394-0.248,0.8-0.248,1.225c0,0.429,0.081,0.834,0.243,1.221
				c0.164,0.387,0.396,0.736,0.704,1.047c0.313,0.306,0.657,0.537,1.05,0.702c0.389,0.163,0.795,0.245,1.222,0.247
				c0.43-0.001,0.841-0.082,1.238-0.247c0.396-0.165,0.754-0.402,1.068-0.71c0.305-0.292,0.526-0.632,0.687-1.009
				C269.601,32.892,269.669,32.479,269.673,32.033z M266.557,29.875c0.584,0,1.018,0.092,1.303,0.28
				c0.28,0.188,0.423,0.478,0.42,0.871c0.002,0.292-0.087,0.54-0.268,0.735c-0.179,0.199-0.415,0.319-0.713,0.364l0.931,1.923h-0.726
				l-0.876-1.835h-0.976l-0.002,1.835h-0.621l-0.001-4.173H266.557L266.557,29.875z M266.438,30.298h-0.781v1.5l0.781-0.001
				c0.438,0,0.751-0.054,0.927-0.165c0.181-0.112,0.271-0.298,0.269-0.563c0-0.273-0.094-0.473-0.273-0.588
				C267.161,30.361,266.856,30.298,266.438,30.298z"/>
			<path id="logo_link" opacity="0" fill="#000000" d="M0,0l69.05,69.05C71.772,71.771,77.15,74,81,74h185.399c3.85,0,8.575-2.729,10.5-6.063L316.123,0H0z" pointerEvents="auto" onPointerDown={() => {setLogoFlagFillState("#B2B2B2")}} onPointerUp={() => {setLogoFlagFillState("#333333")}} onPointerLeave={() => {setLogoFlagFillState("#333333")}} onTouchStart={() => {setLogoFlagFillState("#B2B2B2")}} onTouchEnd={() => {setLogoFlagFillState("#333333")}} onClick={() => {navigateMenu("/")}}/>

		</svg>

    };

	function navigateMenu(navRoute) {

		if ((page !== undefined && navRoute !== "/" + page) || (page === undefined && navRoute !== "/")  || location.pathname === "/404" || location.pathname === "/500") {

			setMenuLocked(true);

			setMediaLoaderOpaState(1);
        	setMediaLoaderVisState("visible");
			scrollToTop("instant");

			setMenuLocked(false);

        	navigate(navRoute);

		};

    };

    function handleMenuClick() {

        handleMenu("click");

    };

	function handleQuantityMinusClick(indexClick) {

		let cartContentTemp = JSON.parse(localStorage.getItem("cartContent"));

		cartContentTemp.forEach((item, index) => {

            if (index === indexClick) {

                item.count = Math.max(item.count - 1, 0);

				if (item.count === 0) {

					cartContentTemp.splice(index, 1);

				};

            };

        });

		cartContentUpdate(cartContentTemp);

	};

	function handleQuantityPlusClick(indexClick) {

		let cartContentTemp = JSON.parse(localStorage.getItem("cartContent"));

		cartContentTemp.forEach((item, index) => {

            if (index === indexClick) {

                item.count = Math.min(item.count + 1, 99);

            };

        });

		cartContentUpdate(cartContentTemp);

	};

	function handleCountryButtonClick() {
		if(checkoutPanelCountryPanelState === false) {
			setCheckoutPanelCountryPanelState(true);
		} else if (checkoutPanelCountryPanelState === true) {
			setCheckoutPanelCountryPanelState(false);
		};
	};

	function handleCheckoutPanelClick() {
		if (checkoutPanelCountryPanelState === true) {
			setCheckoutPanelCountryPanelState(false);
		};
	}

	//useEffect on initial render
  	useEffect(() => {

  	  	fetchShippingEstimate();
                                                                    // eslint-disable-next-line
  	}, []);

	//useEffect on page parameter change
	useEffect(() => {

		handleCheckoutPanel(false, false);
                                                                    // eslint-disable-next-line
    }, [page]);

	//useEffect on checkoutPanelCountryState change
  	useEffect(() => {

  	  	if (shippingCountriesRef.current.length > 0 && checkoutPanelCountryState !== "select_country") {
  	  	  	setShippingCostState(parseFloat(shippingCountriesRef.current.find(country => country.name === checkoutPanelCountryState).price_standard_letter));
			setShippingDaysState(parseFloat(shippingCountriesRef.current.find(country => country.name === checkoutPanelCountryState).delivery_days_standard_letter));
  	  	};
                                                                    // eslint-disable-next-line
  	}, [checkoutPanelCountryState]);

	//useEffect on cartTotalState or shippingCostState change
	useEffect(() => {

		if (cartContentState) {
			setCartTotalState(getCartTotal(cartContentState));
		}
                                                                    // eslint-disable-next-line
  	}, [cartContentState, shippingCostState]);

	//useEffect on paypalSuccessState change
	useEffect(() => {

		if (paypalSuccessState === true) {
			setCheckoutPanelContentState("success");
		};
																	// eslint-disable-next-line
	}, [paypalSuccessState]);

    return (
        <header id="header">
			{checkoutPanelState === true ?
                ((() => {
                    if (checkoutPanelContentState === "cart") {
                        return (
							<div id="checkout_panel" ref={checkoutPanelRef} onClick = {() => {handleCheckoutPanelClick()}}>
								<div id="checkout_panel_row_margin"></div>
								<div id="checkout_panel_row">
									<div id="checkout_panel_cell_type">product</div>
									<div id="checkout_panel_cell_price">price</div>
									<div id="checkout_panel_cell_count_title">quantity</div>
									<div id="checkout_panel_cell_total">total</div>
                				</div>
								<div id="checkout_panel_row_spacer"></div>
								{
                				    cartContentState.map((value, index)=>{
                				        return(
											<div id="checkout_panel_row" key={index}>
												{value.color !== "standard" ?
													<div id="checkout_panel_cell_type">{value.type.replaceAll("njbdesign", "njb design").replaceAll("ammomaker", "ammo maker").replaceAll("_", " ") + " " + value.color}</div>
													: <div id="checkout_panel_cell_type">{value.type.replaceAll("njbdesign", "njb design").replaceAll("ammomaker", "ammo maker").replaceAll("_", " ")}</div>
												}
												<div id="checkout_panel_cell_price">{value.price}</div>
												<div id="checkout_panel_cell_count">
													<svg version="1.1" id="checkout_panel_minus_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" pointerEvents="none">
                			                    	    <rect id="checkout_panel_minus_bg" fill="#000000" opacity="0" width="36" height="36" pointerEvents="auto" onClick = {() => {handleQuantityMinusClick(index)}}/>
                			                    	    <rect id="checkout_panel_minus_icon" x="9.647" y="16.807" fill="#FFFFFF" width="16.705" height="2.387"/>
                			                    	</svg>
													{value.count}
													<svg version="1.1" id="checkout_panel_plus_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" pointerEvents="none">
                			                    	    <rect id="checkout_panel_plus_bg" fill="#000000" opacity="0" width="36" height="36" pointerEvents="auto" onClick = {() => {handleQuantityPlusClick(index)}}/>
                			                    	    <polygon id="checkout_panel_plus_icon" fill="#FFFFFF" points="26.353,19.193 26.353,16.807 19.193,16.807 19.193,9.647 16.807,9.647 16.808,16.807 9.647,16.807 9.647,19.193 16.807,19.193 16.807,26.353 19.193,26.353 19.193,19.193 "/>
                			                    	</svg>
												</div>
												<div id="checkout_panel_cell_total">{parseFloat((value.price * value.count)).toFixed(2)}</div>
											</div>
                				        )
                				    })
                				}
								<div id="checkout_panel_row_spacer"></div>
								<div id="checkout_panel_row">
									<div id="checkout_panel_cell_type">shipping</div>
									<div id="checkout_panel_cell_country">
										{checkoutPanelCountryPanelState === true ?
											<div id="checkout_panel_country_overlay">
												{
													shippingCountriesRef.current.map((country, index)=>{
														return(
															<div id="checkout_panel_country_overlay_item" key={index} onClick = {() => {setCheckoutPanelCountryState(country.name)}}>{country.name.replaceAll("_", " ")}</div>
														)
													})
												}
											</div>
											: <div 	id="checkout_panel_country_button"
													onClick = {() => {handleCountryButtonClick()}}
													style={{backgroundColor: countryButtonFillState}}
													onPointerDown={() => {setCountryButtonFillState("#66666666")}}
													onTouchStart={() => {setCountryButtonFillState("#66666666")}}
													onPointerUp={() => {setCountryButtonFillState("#00000066")}}
													onPointerLeave={() => {setCountryButtonFillState("#00000066")}}
													onTouchEnd={() => {setCountryButtonFillState("#00000066")}}
											>{checkoutPanelCountryState.replaceAll("_", " ")}</div>
										}
									</div>
									<div id="checkout_panel_cell_total">{parseFloat(shippingCostState).toFixed(2)}</div>
          						</div>
								{checkoutPanelCountryState !== "select_country" ?
									<div id="checkout_panel_row_shipping">
										<div id="checkout_panel_cell_type"></div>
										<div id="checkout_panel_cell_shipping_time">shipping time: ~{shippingDaysState} days</div>
										<div id="checkout_panel_cell_total"></div>
									</div>
									:
									<div id="checkout_panel_row_shipping"></div>
								}
								<div id="checkout_panel_row_final_total"><div id="checkout_panel_cell_final_total"><div id="checkout_panel_cell_final_total_usd">{"~USD " + parseFloat(cartTotalState * exRateChfUsdRef.current).toFixed(2)}</div>{"CHF " + parseFloat(cartTotalState).toFixed(2)}</div></div>
								{checkoutPanelCountryState !== "select_country" ?
									<div id="checkout_panel_column_paypal">
										<PayPalButton
											country={checkoutPanelCountryState}
											cartContent={cartContentState}
											setPaypalOrderIdRef={setPaypalOrderIdRef}
											setError={setError}
										/>
										<div id="checkout_panel_cell_paypal_description">paypal, debit or credit card</div>
										<div id="checkout_panel_cell_paypal_error">{error && <div className="error">{error}</div>}</div>
									</div>
									:
									null
								}
								<div id="checkout_panel_row_margin"></div>
							</div>
                        )
                    } else if (checkoutPanelContentState === "success") {
                        return (
							<div id="checkout_panel" ref={checkoutPanelRef} onClick = {() => {handleCheckoutPanelClick()}}>
								<div id="checkout_panel_success">
									<div id="checkout_panel_success_h1">THANK YOU</div>
									<div id="checkout_panel_success_h2">We appreciate your support!</div>
									You will be notified via email as soon as your order is ready for shipping.<br/>If you have any questions please contact us at <a href="mailto:store@njb.design">store@njb.design</a>
									<h2>Order {paypalOrderIdRef.current}</h2>
								</div>
							</div>
                        )
                    }
                })())
				:
				null
			}
			{checkoutPanelButtonState ?
				<svg version="1.1" id="checkout_panel_svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 320.25 140" preserveAspectRatio="xMaxYMin meet" pointerEvents="none">
					<path id="checkout_panel_bg" fill="#000000" opacity="0.75" d="M127.37,0l127.645,127.644c3.89,3.89,9.321,3.174,12.071-1.589l53.264-92.257V0H127.37z"/>
					{checkoutPanelState === true ?
						<polygon id="checkout_panel_icon_close" fill="#FFFFFF" points="252.938,104.75 258,99.688 263.063,104.75 264.75,103.063 259.688,98 264.75,92.938 263.063,91.25 258,96.313 252.938,91.25 251.25,92.938 256.313,98 251.25,103.063 "/>
						:
						<g>
							<polygon id="checkout_panel_icon_cart_pack_y" fill="#FFFF00" points="259.374,93.745 260.674,89.154 265.575,90.543 264.671,93.745 "/>
							<rect id="checkout_panel_icon_cart_pack_g" fill="#CCFF33" x="253.883" y="85.962" transform="matrix(0.9858 -0.1681 0.1681 0.9858 -11.2308 44.3724)" width="5.1" height="5.099"/>
							<polygon id="checkout_panel_icon_cart_pack_b" fill="#00CCFF" points="248.588,93.745 248.015,92.543 252.614,90.35 254.231,93.745 "/>
							<path id="checkout_panel_icon_cart" fill="#FFFFFF" d="M244.868,95.188l9.911,9.91
								c0.457,0.457,1.358,0.829,2.005,0.829h4.796c0.647,0,1.44-0.458,1.764-1.019l5.614-9.723h-24.09V95.188z M251.949,107.849
								c0,1.338-1.085,2.42-2.421,2.42c-1.334,0-2.42-1.082-2.42-2.42c0-1.337,1.086-2.419,2.42-2.419
								C250.864,105.43,251.949,106.512,251.949,107.849z M268.802,107.849c0,1.338-1.084,2.42-2.42,2.42c-1.335,0-2.422-1.082-2.422-2.42
								c0-1.337,1.087-2.419,2.422-2.419C267.718,105.43,268.802,106.512,268.802,107.849z M272.868,93.745l-2.214-2.211
								c-0.103-0.104-0.304-0.188-0.448-0.188h-1.07c-0.146,0-0.323,0.101-0.396,0.228l-1.251,2.171H272.868z"/>
						</g>
					}
					<path id="checkout_panel_link" fill="#000000" opacity="0" d="M127.37,0l127.645,127.644c3.89,3.89,9.321,3.174,12.071-1.589l53.264-92.257V0H127.37z" pointerEvents="auto" onClick={checkoutPanelState ? () => {handleCheckoutPanel(false, false)} : () => {handleCheckoutPanel(true, false)}}/>
				</svg>
				:
				null
			}
			<nav>
				{menu_bg.svg}
                {menu.svg}
            </nav>
			{logo.svg}
        </header>
    )
};